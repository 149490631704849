import React, { Component } from "react"
import SiteMetadata from "../components/site-metadata"

import "../styles/main.css"

class DefaultLayout extends Component {
  render() {
    return (
      <div>
        <SiteMetadata />

        <div>{this.props.children}</div>
      </div>
    )
  }
}

export default DefaultLayout
