import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/index"

export default ({ data }) => {
  const meta = data.site.siteMetadata
  
  return (
    <Layout>
      <div className="msg-under">
        <div className="logo" />
        <h1>PRÓXIMAMENTE</h1>
      </div>

      <div className="contact-bar">
        {meta.phone} | {meta.email}
      </div>
      <div />
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        twitter
        phone
        email
      }
    }
  }
`
